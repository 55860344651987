import React from "react"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import styled from "styled-components";
import Link from "gatsby-link";
import StyledH1 from "../styles/StyledH1";


const PostWrapper = styled.article`
  max-width: 45rem;
  margin: 60px auto;
  padding: 15px;
  a {
    color: ${props => props.theme.red};
  }
`;


export default function SmartCatalog() {


  return (
    <Layout grayFooter={true}>

      <PostWrapper>
        <StyledH1>Smart Catalog — Free Migrations to Clean Catalog</StyledH1>
        <h2>Clean Catalog is the best Smart Catalog alternative</h2>

        <Link to={'/case-studies/coastalpines-smart-catalog/'}>Read Our Case Study on Switching to Clean Catalog from
          Smart Catalog</Link>

        <p>For all current Smart Catalog customers, we offer free migrations to Clean Catalog. We take care of
          everything, and
          you get a great product at a better price.</p>

        <p>We don't charge an up-front fee for the migration from Smart Catalog, and Clean Catalog's annual cost is
          typically less
          than
          Smart Catalog's.</p>

        <p>For a comparison of features between Clean Catalog and Smart Catalog, visit <a
          href="https://coursecatalogsoftware.com/">our course catalog software comparison chart.</a></p>

      </PostWrapper>
    </Layout>
  )

}

export function Head(props) {
  return (
    <Seo
      title="Smart Catalog"
      description='How to migrate from Smart Catalog to a modern course catalog management system — Clean Catalog.'
      canonical={props.location.pathname}
    />
  )
}

